<template>
  <Container v-if="product" space-before space-after>
    <div class="fragrance-details">
      <div class="fragrance-info-head">
        <div class="image-wrap">
          <img
            class="image"
            :src="imageSrc || ''"
            :alt="imageAlt"
            loading="lazy"
          />
        </div>
        <div class="content">
          <span class="name">
            {{ product.name }}
          </span>
          <NuxtLink class="product-link" :to="productUrl"> Shop Now </NuxtLink>
        </div>
      </div>
      <div class="info-container">
        <div class="fragrance-info">
          <Headline :level="5">Fragrance Info</Headline>
          <PropertyTable :properties="product.fragrance.infos" />
        </div>
        <div class="note-profile">
          <Headline :level="5">Note Profile</Headline>
          <NoteProfile v-if="noteProfile" :notes="noteProfile" />
        </div>
      </div>
    </div>
  </Container>
</template>

<script>
  import { deserialize } from "@alchemy_cms/json_api"

  import PropertyTable from "~/components/PropertyTable"
  import NoteProfile from "~/components/NoteProfile"
  import Headline from "~/components/Headline"
  import Container from "~/components/Container"

  export default {
    components: {
      Container,
      NoteProfile,
      PropertyTable,
      Headline,
    },
    props: {
      productId: {
        type: Number,
        required: true,
      },
      elementId: {
        type: String,
        required: true,
      },
      elementName: {
        type: String,
        required: true,
      },
    },

    setup(props) {
      const { handleError } = useErrorHandling()
      const { api } = useApi()
      const { data } = useLazyAsyncData(
        `${props.elementName}-${props.elementId}`,
        async () => {
          try {
            const data = await api(`/jsonapi/products/${props.productId}`, {
              params: {
                include: "variants.images,fragrance",
              },
            })
            return deserialize(data)
          } catch (error) {
            handleError(error)
          }
        },
      )
      return {
        product: data || {},
      }
    },

    computed: {
      noteProfile() {
        const components = this.product.fragrance.components

        const filterNotesByPosition = () => {
          return components.reduce(
            (acc, note) => {
              const { position, ...rest } = note
              acc[position.toLowerCase()].push(rest)
              return acc
            },
            { top: [], middle: [], base: [] },
          )
        }
        return filterNotesByPosition()
      },
      imageSrc() {
        return this.product.variants[0]?.images[0]?.smallUrl
      },
      imageAlt() {
        return this.product.variants[0]?.images[0]?.alt
      },
      productUrl() {
        return this.product.urlPath || ""
      },
    },
  }
</script>

<style lang="scss" scoped>
  .fragrance-details {
    padding: $gutter;
    border-radius: $border-radius-large;
    background: var(--shade);
  }

  .fragrance-info-head {
    display: flex;
    margin-bottom: $gutter;
    padding-bottom: $gutter;
    border-bottom: 1px solid var(--border);
  }

  .product-link {
    color: var(--link);
    text-decoration: none;
    @include type("lg");
    .cs-dark & {
      color: $gray-200;
    }
    &:hover,
    &:focus {
      color: $blue;
      outline: none;
      text-decoration: underline;
    }
  }
  .content {
    padding-left: $gutter;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
  }
  .name {
    @include type("h3");
  }
  .image-wrap {
    flex-shrink: 0;
    width: 96px;
    height: 96px;
    transition:
      transform 120ms ease-in-out,
      box-shadow 120ms ease-in-out;
    position: relative;
    box-shadow: $shadow;
    border-radius: $border-radius-large;
    &:hover {
      transform: scale(1.05);
      box-shadow: $hover-shadow;
    }
  }
  .image {
    width: 100%;
    height: 100%;
    border-radius: $border-radius-large;
  }
  .info-container {
    display: flex;
    gap: $gutter;
    @include viewport("mini", "sm") {
      flex-direction: column;
    }
  }
  .fragrance-info {
    flex: 1;
  }
  .note-profile {
    flex: 1;
  }
</style>
